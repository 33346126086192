<template>
  <section id="section-offer" class="bg-monochrome-grey-super-light-grey py-50px">
    <div v-scrollanimation="'up'" class="nstra-container">
      <b-row class="align-items-center">
        <b-col cols="12" lg="3">
          <h3 class="section-title mb-30px mg-lg-0px">
            Our
            <span class="text-primary">way.</span>
          </h3>
        </b-col>
        <b-col cols="12" lg="9">
          <b-row>
            <b-col cols="12" lg="4">
              <div class="mb-30px mb-lg-0px">
                <div class="offer-icon-wrapper">
                  <img width="60" height="60" class="offer-icon" src="@/assets/icons/ico-offer-teams.svg" alt="one team one project image" />
                </div>
                <h4 class="offer-service-title">One Team One Project</h4>
                <p class="offer-service-desc">
                  For each project, we dedicate a team of experts focusing on the successful delivery of one single project at a time.
                </p>
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div class="mb-30px mb-lg-0px">
                <div class="offer-icon-wrapper">
                  <img width="60" height="60" class="offer-icon" src="@/assets/icons/ico-offer-agile.svg" alt="ARC image" />
                </div>
                <h4 class="offer-service-title">ARC</h4>
                <p class="offer-service-desc">
                  Using Agile Scrum Methodology, we ensure Adaptive planning, Rapid delivery and Continuous development and improvement.
                </p>
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div>
                <div class="offer-icon-wrapper">
                  <img width="60" height="60" class="offer-icon" src="@/assets/icons/ico-offer-partnership.svg" alt="partnership embed image" />
                </div>
                <h4 class="offer-service-title">Partnership Embed</h4>
                <p class="offer-service-desc">
                  We embed our team within the customer’s team to ensure a synergetic approach with clear and transparent communication between both parties.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  name: "OfferSection",
  components: {
    BRow,
    BCol,
  },
};
</script>
